.full-height{
  height: 100vh;
  background: linear-gradient(to bottom, #0d1d31, #0c0d13);
}
.productPage {
    padding: 20px;
    max-width: 100%;
    margin: auto;
    background-color: #f0f0f000;
}

  .productCardLink {
    text-decoration: none;
  }
  
  .productCard {
    padding: 20px;
    text-align: center;
    border-radius: 15px;
    overflow: hidden;
    background-color: #FFFFFF;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-in-out;
    position: relative;
  }

  .productCard::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background-color: #3f51b5;
    z-index: -1;
    transform: rotate(45deg);
    transition: all 0.5s ease-in-out;
  }
  
  .productCard:hover {
    color: #FFFFFF;
    transform: scale(1.05);
  }
  
  .productCard:hover::before {
    top: -30%;
    left: -30%;
  }
  
  .product-name {
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .product-details {
    font-weight: lighter;
  }
  .product-grid {
    padding-left: 20px;
    padding-right: 20px;
  }

  .back-button {
    position: fixed;
    left: 0;
    bottom: 0;
    margin: 10px;
    color: #ffffff;
    z-index: 1000;
    font-size: 2rem;
    width: 50px;
    height: 50px;
    line-height: 50px;
  }

  