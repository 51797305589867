@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

.full-height {
  height: 100vh;
}
.product-container {
  background: linear-gradient(to bottom, #0d1d31, #0c0d13);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  padding: 10px;
  color: white;
}

.product-card {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  padding: 20px;
  width: 25%;
  max-height: 70%;
  overflow: auto;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
  font-weight: 500;
  margin: 10px auto;
}

.product-card::-webkit-scrollbar {
  width: 10px;
}

.product-card::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.product-card::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.product-card::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.product-card h2 {
  font-size: 1.6em;
  font-weight: 700;
  margin-bottom: 15px;
}

.product-card p {
  font-size: 1.2em;
  margin: 15px 0;
}

.product-card ul {
  list-style-type: none;
  padding: 0;
}

.product-card ul li {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  margin: 10px 0;
  padding: 5px; /* Reduced padding to make items in the assigned product list shorter */
}

.product-card ul li a {
  color: rgb(204, 219, 222);
}
@media screen and (max-width: 600px) {
  .product-card {
    width: 90%;
  }
}