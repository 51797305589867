@media (min-width: 700px) {
  .my-button {
    width: auto !important;
  }

  .results-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
}

.my-button {
  margin: 10px auto !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.head {
  text-align: center;
}

.button-sorgu {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.button-group {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.button-group > .my-button {
  flex: 1 0 auto;
  margin: 5px !important;
}

.button-qr {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

/* .containero {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  min-height: 100vh;
  
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
}
.head{
  color: #dedada;
} */