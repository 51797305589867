.mainContainer {
  height: 100vh;
}

.imageContainer {
  background: url(https://source.unsplash.com/random?wallpapers);
  background-repeat: no-repeat;
  background-color: #f5f5f5;
  background-size: cover;
  background-position: center;
}

.paperContainer {
  margin: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.formContainer {
  margin-top: 1px;
}

.submitButton {
  margin-top: 30px;
  margin-bottom: 2px;

}

.message {
  margin-top: 2px;
  color: red;
}

.goBackContainer {
    display: flex;
    justify-content: center;
    margin-top: auto;
    padding-top: 10%;
  }